.card-skl {
    display: flex;
    flex-direction: column;
    flex-basis: 300px;
    flex-shrink: 0;
    flex-grow: 0;
    max-width: 100%;
    background-color: var(--second-color);
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    overflow: hidden;
  }
  
  .card-img-skl {
    padding-bottom: 56.25%;
    position: relative;
  }
  .card-img-skl img {
    position: absolute;
    width: 100%;
  }
  
  .card-body-skl {
    /* padding: 1.5rem; */
  }
  
  .card-title-skl {
    font-size: 1.25rem;
    line-height: 1.33;
    font-weight: 700;
  }
  .card-title-skl.skeleton {
    min-height: 28px;
    border-radius: 4px;
  }
  
  .card-intro-skl {
    margin-top: 0.75rem;
    line-height: 1.5;
  }
  .card-intro-skl.skeleton {
    min-height: 72px;
    border-radius: 4px;
  }
  
  .skeleton {
    background-color: #5050503b;
    margin: 1rem;
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
    background-size: 40px 100%;
    background-repeat: no-repeat;
    background-position: left -40px top 0;
    -webkit-animation: shine 1s ease infinite;
            animation: shine 1s ease infinite;
  }
  
  @-webkit-keyframes shine {
    to {
      background-position: right -40px top 0;
    }
  }
  
  @keyframes shine {
    to {
      background-position: right -40px top 0;
    }
  }
.main-skl{
  width: 200px;
  height: 200px;
  border-radius: 50% 57% 2% 50%;
  justify-content: center;
  align-items: stretch;
position: relative;
margin: 0 auto;
}
.round-skelton{
  width: 170px;
  height: 170px;
  border-radius: 50% 57% 2% 50%;
  margin: 0 auto;
}
.skeleton-w{
  position: absolute;
  right: 25px;
  width: 20px;
  height: 20px;
  z-index: 99;
  bottom: 8px;
  background: #5050503b;
  border-radius: 50%;
  
}
.skl-height{
height: 25vw;
}

.img-item-skl{
  margin: 0 !important;
  height: 219px;
}
@media(max-width: 500px){
  .hide-skl{
    display: none;
  }
}

@media(max-width: 996px){
.skl-non-modal{
  display: none;

}
}